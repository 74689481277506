<template>
  <div id="app">
      <div style="display:flex;margin:10px 0">
        <div  class="item">
          <el-select v-model="deviceType" placeholder="请选择设备类型" clearable>
            <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.name" @click.native="selectOptions(item)">
            </el-option>
          </el-select>
        </div>

        <div  class="item" >
           <el-input v-model="deviceId" placeholder="请输入设备编号" clearable></el-input>
        </div>

        <div  class="item" >
           <el-input v-model="ciId" placeholder="请输入单位" clearable></el-input>
        </div>

        <div  class="item" >
           <el-input v-model="repairTypeId" placeholder="请输入报修类型" clearable></el-input>
        </div>

        <div  class="item">
          <el-date-picker @change="changeTime" 
                v-model="createTime"
                type="datetimerange" 
                :picker-options="pickerOptions" 
                value-format="yyyy-MM-dd HH:mm:ss"
                  start-placeholder="开始日期" 
                  end-placeholder="结束日期">
          </el-date-picker>
        </div>

        
        <div class="item">
          <el-button type="primary"  @click="queryInfo">查询</el-button>
        </div>
        <div class="item" >
          <el-button type="primary"  @click="restList">重置</el-button>
        </div>

        <div class="item" style="margin-left:auto">
          <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
            <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
            </el-option>
          </el-select>
        </div>
      </div>
       <!-- 异常设备 -->
      <div>
        <el-table :stripe="true" :data="abnormalDeviceList">
          <el-table-column v-if="tableTitle.includes('设备编号')" label="设备编号" prop="deviceId" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('设备类型')" label="设备类型" prop="deviceTypeName" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('单位')" label="单位" prop="company" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('安装位置')" label="安装位置" prop="location" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('报修类型')" label="报修类型" prop="repairTypeId" align="center"></el-table-column>
          <el-table-column v-if="tableTitle.includes('时间')" label="时间" prop="createTime" align="center"></el-table-column>
        </el-table>
      </div>
   
    <!-- 分页 -->
    <div>
      <el-pagination :current-page.sync="deviceManagementDeviceInfoVo.current" 
        :page-size="deviceManagementDeviceInfoVo.limit" 
        :total="deviceManagementDeviceInfoVo.total" 
        layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" class="pagination"></el-pagination>
    </div>
  </div>
</template>

<script>

import deviceInfo from '@/api/facilitiesManagement/DeviceInfo'
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  name: "AbnormalDevice",
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '单位',
      }, {
        id: 3,
        title: '安装位置',
      }, {
        id: 4,
        title: '报修类型',
      }, {
        id: 5,
        title: '时间',
      }],
      tableTitle: ['设备编号', '设备类型', '单位', '安装位置', '报修类型', '时间'],

      deviceType: '',
      // 设备类型
      deviceTypeList: [],
      //设备编号
      deviceId:"",
      //公司id
      ciId:"",

      //公司名称
      company:"",
      
      //保修类型
      repairTypeId:"",

      createTime:"",
      // 页码
      deviceManagementDeviceInfoVo:{
        limit: 10,
        current: 1,
        total: 1,
      },
      bbb:"",
      // 所有公司
      companyInfoList: [],

      // 异常设备
      abnormalDeviceList: [],


      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        },
    }
    };

  },
  created() {
    // 查所有公司
    this.getCompanyInfo()

    //   查异常设备
    this.getAbnormalDevicecInfo();

    // 设备类型
    this.getDeviceManagementType()

  },


  methods: {
    //重置按钮的单击事件方法
    async restList() {
      //点击了重置按钮之后，将查询的条件进行只看
      this.deviceType = "";
      this.deviceId = "";
      this.ciId = "";
      this.repairTypeId = "";
      this.createTime = "";
      this.endTime = "";
      //查所有公司
      this.getCompanyInfo()
      //查异常设备
      this.getAbnormalDevicecInfo();
      //设备类型
      this.getDeviceManagementType();
    },
    changeTime(e){
      console.log(e);
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    // 分页
    handleCurrentChange(val) {
      console.log(val);
      this.deviceManagementDeviceInfoVo.current = val
      // deviceId:"",
      // ciId:"",
      // company:"",
      // repairTypeId:"",
      // createTime:"",
      if(this.deviceId || this.ciId|| this.company|| this.repairTypeId|| this.createTime)return this.queryInfo()
     
      this.getAbnormalDevicecInfo();
    },

    // 所有公司
    async getCompanyInfo() {
      const res = await companySystem.getCompanyInfo()

      this.companyInfoList = res.data.data.list;
    },

    // 查询异常设备信息
    async getAbnormalDevicecInfo(deviceManagementDeviceInfoVo) {
      console.log("hh",deviceManagementDeviceInfoVo);
      if (!deviceManagementDeviceInfoVo) {
        console.log(111);
        deviceManagementDeviceInfoVo = this.deviceManagementDeviceInfoVo
      }else {
        console.log(222);
        deviceManagementDeviceInfoVo = {...deviceManagementDeviceInfoVo,...this.deviceManagementDeviceInfoVo}
      }
      console.log(deviceManagementDeviceInfoVo);
      const res = await deviceInfo.getAllAbnormalDevice(deviceManagementDeviceInfoVo)
      console.log("resres",res);

      this.abnormalDeviceList = res.data.data.deviceManagementDeviceInfoList
      this.deviceManagementDeviceInfoVo.total = res.data.data.total;

      if (res.data.message == "无异常设备") {
        this.abnormalDeviceList = []
      } else {
        for (let i = 0; i < this.abnormalDeviceList.length; i++) {
          for (let j = 0; j < this.companyInfoList.length; j++) {
            if (this.abnormalDeviceList[i].company == this.companyInfoList[j].id) {
              this.abnormalDeviceList[i].company = this.companyInfoList[j].name;
            }
          }
        }
      }
    },

    // 设备类型
    getDeviceManagementType() {
      deviceInfo.getDeviceManagementType().then(res => {
        this.deviceTypeList = res.data.data.list;
      })
    },

    selectOptions(item) {
      this.deviceType = item.name;
    },

    // 查询设备
    queryInfo() {
      const [createTime,endTime] = this.createTime
      console.log("查询设备的时间1",createTime);
      console.log("查询设备的时间2",endTime);
      console.log(...this.repairTypeId);
      let deviceManagementDeviceInfoVo = {}
      // let deviceManagementDeviceInfoVo = {
      //   ...this.deviceType,
      //   ...this.ciId,
      //   ...this.deviceId,
      //   ...this.company,
      //   ...this.repairTypeId,
      //   ...createTime,
      //   ...endTime
      // };
      deviceManagementDeviceInfoVo.typeName = this.deviceType;
      deviceManagementDeviceInfoVo.ciId = this.ciId;
      deviceManagementDeviceInfoVo.deviceId = this.deviceId;
      deviceManagementDeviceInfoVo.company = this.company;
      deviceManagementDeviceInfoVo.repairTypeId = this.repairTypeId;
      deviceManagementDeviceInfoVo.createTime =createTime
      deviceManagementDeviceInfoVo.endTime = endTime
      this.getAbnormalDevicecInfo(deviceManagementDeviceInfoVo);
    }

  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin: 10px 10px 10px 0;
}
.item {
  margin: 0 5px;
}
</style>