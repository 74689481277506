import request from '@/../utils/request'
const api_name = '/iotechserver/DeviceManagementDeviceInfoController'

export default {
    // 设备设施管理 --> 设备信息
    pageDeviceManagementDeviceInfo80(deviceInfo, searchInfo) {
        return request({
            url: `${api_name}/pageDeviceManagementDeviceInfo80`,
            method: 'post',
            data: {
                limit: deviceInfo.limit,
                current: deviceInfo.current,
                deviceManagementDeviceInfo: searchInfo,
            }
        })
    },

    // 设备设施管理 --> 异常设备
    getAllAbnormalDevice(deviceManagementDeviceInfoVo) {
        return request({
            url: `${api_name}/getAllAbnormalDevice`,
            method: 'post',
            data:deviceManagementDeviceInfoVo
        })
    },

    // 设备类型
    getDeviceManagementType() {
        return request({
            url: `${api_name}/getDeviceManagementType`,
            method: 'get',
        })
    },

    getDeviceManagementTypeById(typeId) {
        return request({
            url: `${api_name}/getDeviceManagementTypeById/${typeId}`,
            method: 'get',
        })
    },

    //修改
    updataDeviceManagementDeviceInfo(deviceManagementDeviceInfoVo) {
        return request({
            url: `${api_name}/updataDeviceManagementDeviceInfo`,
            method: 'post',
            data: deviceManagementDeviceInfoVo
        })
    },

    // 查询所有异常设备
    getAllDeviceManagementType(current, limit) {
        return request({
            url: `${api_name}/getAllDeviceManagementType/${current}/${limit}`,
            method: 'get',
        })
    },



    // 检修记录
    getAllOverhaulHistory(current, limit, deviceType) {
        return request({
            url: `${api_name}/getAllOverhaulHistory/${current}/${limit}`,
            method: 'get',
            params: {
                deviceTypeName: deviceType
            }
        })
    },

    //查询所有通知记录
    pageNotifyHistoryValue(current, limit, name) {
        return request({
            method: 'get',
            url: `${api_name}/getAllNotifyHistoryValue/${current}/${limit}`,
            params: {
                name: name,
            }
        })
    },

    // 报废设备
    getAllScrapInfo(current, limit, typeId) {
        return request({
            method: 'get',
            url: `${api_name}/getAllScrapInfo/${current}/${limit}`,
            params: {
                typeId: typeId,
            }
        })

    },




}